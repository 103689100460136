<template>
  <PageWithLayout
    :isTitle="true"
    :isBackBtn="true"
    :isBackWebEnd="true"
    :topPadding="24"
    :bottomPadding="24">
    <div class="box_exclam">
      <p class="desc_exclam">자녀의 카드관리 메뉴에서<br />자녀 별로 신청해주세요</p>
      <!-- <span class="tc_red">23년 12월 ~ 24년 2월</span> -->
    </div>
    <div class="item_info">
      <strong class="tit_info">신고 방법 안내</strong>
      <ol class="list_num">
        <li>자녀의 주민번호 제공 및 제3자 정보이용 동의를 해주세요.</li>
        <li>홈택스에서 자녀를 피부양자로 등록해주세요.</li>
        <li>홈택스에 부양자의 선불카드 사용내역으로 자녀의 퍼핀카드 사용 내역이 이관됩니다.</li>
      </ol>
      <ul class="list_dot">
        <li>용돈을 충전/지급한 분 및 가족대표자와 홈택스에 등록한 부양자는 일치하지 않아도 무방합니다.</li>
        <li>개인 별 사용내역 및 증빙 문서 출력 서비스는 제공하지 않습니다.</li>
        <li>레일플러스 선불교통카드 사용금액에 대한 소득공제 신청은 레일플러스 홈페이지에서 신청할 수 있습니다.</li>
      </ul>
    </div>
    <div class="item_info">
      <strong class="tit_info">소득공제 안내</strong>
      <ul class="list_dot">
        <li>과세연도 총 급여액의 25%를 넘는 사용액부터 소득공제 혜택을 받을 수 있습니다.</li>
        <li>선불카드 소득공제율은 30%이며 신용카드 15%보다 높습니다.</li>
        <li>해당 사용을 초과하는 구간부터 선불카드 결제 이력은 절세에 도움 됩니다.</li>
      </ul>
    </div>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@/components/layout/PageWithLayout';
// mixins
import InterFaceMixin from '@/mixins/InterFaceMixin'
import BackKeyEventMixin from '@/mixins/BackKeyEventMixin';

export default {
  name: 'DeductionsAndExemptionsInfo',
  mixins: [ InterFaceMixin, BackKeyEventMixin ],
  components:{
    PageWithLayout,
  },
  methods:{
    onClickBackKey(){
      this.sendInterface('webEnd');
    }
  }
}
</script>

<style scoped>
.box_exclam{margin:0 20px;padding:12px 16px 12px 68px;border-radius:8px;background:#F7F6FA url('/assets/images/etc/icon_exclam.png') no-repeat 16px 14px;background-size:40px}
.box_exclam .desc_exclam{font-weight:500;font-size:14px;line-height:22px;color:#111;word-wrap:break-word;word-break:keep-all}
.box_exclam .tc_red{display:block;margin-top:4px;font-weight:500;font-size:12px;line-height:16px}

.item_info{padding:24px 20px;}
.item_info + .item_info{border-top:12px solid #F7F6FA}

.tit_info{font-weight:700;font-size:18px;line-height:28px;color:#111}

.list_dot li{padding-left:28px;font-weight:500;font-size:16px;line-height:24px;color:#333;word-wrap:break-word;word-break:keep-all;text-indent:-28px}
.list_dot li:before{display:inline-block;width:4px;height:4px;margin:10px 16px 10px 8px;border-radius:100%;background-color:#333;vertical-align:top;content:''}
.tit_info + .list_dot,
.list_dot li + li{margin-top:20px}

.list_num{padding-left:28px;counter-reset:li;text-indent:-28px}
.list_num li{font-weight:500;font-size:16px;line-height:24px;color:#333;word-wrap:break-word;word-break:keep-all}
.list_num li:before{display:inline-block;width:20px;height:20px;margin:2px 8px 0 0;padding-top:2px;border-radius:100%;font-family:'Poppins';font-weight:600;font-size:12px;line-height:16px;background-color:#BEA9ED;color:#232323;vertical-align:top;text-align:center;content:counter(li);counter-increment: li;box-sizing:border-box;text-indent:0}
.tit_info + .list_num,
.list_num li + li{margin-top:24px}


.list_num + .list_dot{margin-top:20px;padding-top:20px;border-top:3px solid #F7F6FA}
</style>